<template>
  <div>
    <!-- 体现中2 -->
    <withdrawal-table :auditStatus="2"></withdrawal-table>
  </div>
</template>
<script>
import withdrawalTable from '@/components/supplier/withdrawalTable.vue'
export default {
  components: { withdrawalTable },
  data() {
    return {
     
    }
  }
}
</script>
<style lang="scss" scoped>

</style>